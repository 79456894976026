@import url(//db.onlinewebfonts.com/c/685030e1abf58bd087a4ee725d9dea6a?family=Blender+Pro);

@font-face {
  font-family: 'Blender Pro';
  src: url("./assets/fonts/blender.ttf") format("truetype");
}

@font-face {
  font-family: 'Cyberion';
  src: url("./assets/fonts/Cyberion-Regular.ttf") format("truetype");
}



a {
  color: white ;
  text-decoration: none;
  opacity: 0.4;
  text-align: center;
}

.nav-itemcss {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  color: white;
  margin-right: 10px;
  opacity: 1;
}
.navbar-brandcss {
  margin-right: 0%;
  margin-left: 10px;
  margin-right: 10px;
  
}

.hoveritem {
  font-size: 1rem;
}

.hoveritem:hover {
  color: #DB6C39;
  text-decoration: none;
}

body{
  font-family: 'Blender Pro', sans-serif;
  background-color: whitesmoke;
  overflow-x:hidden;
}


video.fillWidth {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center center;
  opacity: 0.4;
}

.margincard{
  margin-bottom: 10%;
}

.cardincontactpage{
  top: 7%;
  right: 10%;
  height: 85%;
  width: 25%;
}

.img-hover-zoom {
  transition: transform 1s ease;
  
}



/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover  {
  transform: scale(1.1);
  z-index: 10;
  color: white;
  
}

.card-home-img-background {
  opacity: 1;
  cursor: pointer;
}

/* .card-home-img-background:hover {
  opacity: 0.5;
  cursor: pointer;
} */

.card-home-text:hover  + .card-home-img-background {
  opacity: 0.5;
  cursor: pointer;
}

.form-home-container {
  -webkit-box-shadow: 1px 2px 9px 3px rgba(189,189,189,0.86);
  -moz-box-shadow: 1px 2px 9px 3px rgba(189,189,189,0.86);
  box-shadow: 1px 2px 9px 3px rgba(189,189,189,0.86);
}



.margin-top-devices {
  margin-top: 25%;
}

.home-colform {
  max-height: 100%;
}

.home-text-quienes-somos {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.home-colform-form {
  margin: 50px;
}

.home-colform-container {
  max-height: 100%;
  overflow-y: hidden;
}

.footer-logo {
  width: 7%;
}

.home-text-jobs {
  font-size: 6rem;
  line-height: 5rem;
}

.flare1 {
  top: 130%
}

.flare2 {
 bottom: 30%
}

.home-logo {
  width: 100%;
}

.home-polvo {
  top: 1.2%;
  left: 0;
  width: 100%;
  height: 98.5%;
}

#aboutTitle {
  font-size: 3rem;
  margin-right: 3rem!important;
}

#about {
 font-size: 1.2rem;
 border-left: 1px solid black;
 width: 49%;
 padding: 0 2rem
}


.about {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

#about2 {
  font-size: 1.2rem;
  border-left: 1px solid black;
  width: 100%;
  padding: 0 2rem;
  text-align: center;
 }

.about2 {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.aboutDiv {
  width: 45%;
  display: flex;
  align-items: start;
  justify-content: start;
  z-index: 2;
}

.cardSectionServices{
   height: 65vh;
   width: 100%;
   border-radius: 30px;
}

.sectionServices {
  background-size: contain;
  background-repeat: no-repeat;
}

.sectiionMilton { 
  display: flex;
  align-items: center;
  justify-content: center;
}

.sectionMilton_text{
  width: 80%;
  font-size: 1.44rem;
  color: white;
  text-align: start;
  padding: 0 2rem;
}

.sectionMilton_milton{
  width: 60%;
  
}

#separador1 {
  top: 0px;
}

.texto-form {
  margin-right: 4.6rem;
}
/* 
.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
  transition:  1700ms;
  animation-timing-function: linear;
}

.slide {
  display: inline-block;

  height: 200px;
  width: 30%;

}



.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
} */


@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: black;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), black);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), black);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 32s slide infinite linear;
}

.logos-slide img {
  height: 100px;
  margin: 0 40px;
}

@media (max-width: 1476px) {
  .margin-top-devices {
    margin-top: 30%;
  }
}

@media (max-width: 991px) {
  .margin-top-devices {
    margin-top: 50%;
  }
}

@media (max-width: 768px) {

  .texto-form {
    margin-right: 0px;
  }
  

  #separador1 {
    top: 0px;
  }

  .sectionMilton_milton{
    width: 100%;
    margin-bottom: 3rem!important;
  }

  .sectionMilton_text{
    width: 100%;
    font-size: 1.21rem;
    color: white;
    text-align: center;
    padding: 0 0 0 0;
  }

  .sectiionMilton { 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .sectionServices {
    background-size: cover;
    background-repeat: round;
    
  }
  
  .cardSectionServices{
    height: 25vh;
    width: 100%;
    border-radius: 30px;
  }

  .about {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .about2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  #aboutTitle {
    font-size: 1.5rem;
    text-align: center;
    margin-right: 0rem!important;
  }

  #about {
    width: 100%;
    padding: 0 0 0 0;
    margin-top: 10px;
   font-size: 0.8rem;
   /* border-top: 1px solid black; */
   border-left:none;
   text-align: center;
  }

  #about2 {
    width: 100%;
    padding: 0 0 0 0;
    margin-top: 10px;
   font-size: 0.6rem;
   /* border-top: 1px solid black; */
   border-left:none;
   text-align: center;
  }

  input::placeholder {
    color: red;
    font-size: 1.2em;
    font-style: italic;
  }

  .aboutDiv {
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    z-index: 2;
  }

  .margincard{
    margin-bottom: 70%;
    margin-top: 20%;
  }

  .cardincontactpage{
    top: 5%;
    right: 15%;
    height: 90%;
    width: 70%;
  }

  .margin-top-devices {
    margin-top: 135%;
  }

  .home-colform {
    max-height: 80%;
    margin: 0%;
  }

  .home-text-quienes-somos {
    width: 100%;
    font-size: 0.6rem;
  }
  .home-colform-container {
    max-height: 100%;
    overflow-y: hidden;

  }

  .footer-logo {
    width: 30%;
  }

  
  .hoveritem {
    font-size: 0.6rem;
  }

  .home-text-jobs {
    margin-top: 20%;
    font-size: 3rem;
    line-height: 2.5rem;
  }

  .frases {
    margin-top: 10px;
  }

  .collapse {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .navbar-brand {
    justify-content: center;
  }
   
  .flare1 {
    top: 145%
  }

  .flare2 {
    bottom: 50%
  }

  .home-logo {
    width: 90%;
    
  }

  .home-polvo {
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .home-colform-form {
    margin: 10px;
  }
  
}


/* @media (max-width: 414px) {
  .margin-top-devices {
    top: 195%;
  }
}



@media (max-width: 375px) {


  .margin-top-devices {
    top: 215%;
  }
  
} */

/* HACER QIUE LAS BARRAS DE SCROLL TENGAN BACKGROUND COLOR Y EL BOTON SEAN #DB6C39 */


/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #DB6C39 #06050A;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #06050A;
}

*::-webkit-scrollbar-thumb {
  background-color: #DB6C39;
  border-radius: 20px;
  border: 3px solid #DB6C39;
}

.frases {
  font-family: 'Cyberion', 'Blender Pro', sans-serif;
}


@keyframes backInRight {
  0% {
    transform: translateX(2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes backInLeft {
  0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes backInDown {
  0% {
    transform: translateY(-1200px) scale(0.7);
    opacity: 0;
  }

  80% {
    transform: translateY(0px) scale(0.7);
    opacity: 0.7;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}



/* @keyframes show {
  from {
    opacity: 0;
    scale: 25%;
  }

  to {
    opacity: 1;
    scale: 100%;
  }

  0% {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
  }

  80% {
    transform: translateX(0px) scale(0.7);
    opacity: 0.9;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
  
} */

.img-projects0 {
  view-timeline-name: --image;
  view-timeline-axis: block;

  animation-timeline: --image;
  animation-name: backInLeft;

  animation-range: entry 25% cover 50%;
  animation-fill-mode: both;
}

.img-projects1 {
  view-timeline-name: --image;
  view-timeline-axis: inherit;

  animation-timeline: --image;
  animation-name: backInRight;

  animation-range: entry 25% cover 50%;
  animation-fill-mode: both;
}